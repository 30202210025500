<template>
  <main :class="'page-' + this.$route.name?.toLowerCase()">
    <div class="container">
      <Menu />
      <!-- <router-link to="/shopping-list" class="shopping-list-button"><i class="far fa-fw fa-shopping-bag"></i></router-link> -->
      <router-view :recipes="recipes" :tips="tips" />
    </div>
  </main>
  <Footer />
</template>

<script>
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Menu,
    Footer
  },
  data () {
    return {
      recipes: [],
      tips: [],
      popupList: []
    }
  },
  created () {
    const files = require.context('@/recipes/', true, /recipe.json$/i).keys()
    console.log(files.length + ' recipes loaded')
    this.loadRecipes(files)

    const tipFiles = require.context('@/tips/', true, /tip.json$/i).keys()
    console.log(tipFiles.length + ' tips loaded')
    this.loadTips(tipFiles)
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    async loadRecipes (files) {
      files.forEach((path, i) => {
        const recipe = this.getRecipe(path)
        if (recipe.published) {
          this.recipes.push(recipe)
        }
      })
      this.recipes.sort((a, b) => {
        a = a.datePublished.split('-').reverse().join('')
        b = b.datePublished.split('-').reverse().join('')
        return a < b ? 1 : a > b ? -1 : 0
      })
    },
    getRecipe (path) {
      const dirname = path.substr(2, path.length - 2 - '/recipe.json'.length)
      try {
        const model = require('@/recipes/' + dirname + '/recipe.json')
        return { path: dirname, ...model }
      } catch (e) {
        console.log('Cannot find recipe')
      }
    },
    async loadTips (files) {
      files.forEach((path, i) => {
        this.tips.push(this.getTip(path))
      })
      this.tips.sort((a, b) => {
        a = a.datePublished.split('-').reverse().join('')
        b = b.datePublished.split('-').reverse().join('')
        return a < b ? 1 : a > b ? -1 : 0
      })
    },
    getTip (path) {
      const dirname = path.substr(2, path.length - 2 - '/tip.json'.length)
      try {
        const model = require('@/tips/' + dirname + '/tip.json')
        return { path: dirname, ...model }
      } catch (e) {
        console.log('Cannot find tips')
      }
    },
    addRecipeToShoppingList (recipe) {
      let recipeIngredients = []

      recipe.steps.forEach(function (step) {
        if (step.ingredients) {
          recipeIngredients = recipeIngredients.concat(
            step.ingredients.map((i) => {
              return { amount: i.amount, unit: i.unit, name: i.name }
            })
          )
        }
      })

      this.shoppingList = this.shoppingList.concat(recipeIngredients)
    },
    deleteShoppingListItem (name) {
      if (confirm('Wil je “' + name + '” verwijderen uit je boodschappenlijst?')) {
        this.shoppingList = this.shoppingList.filter((item) => item.name !== name)
      }
    }
  }
}
</script>

<style lang="scss" global>
@import '@/assets/css/colors.scss';
@import '@/assets/scss/fontawesome.scss';
@import '@/assets/scss/regular.scss';
@import '@/assets/scss/solid.scss';
@import '@/assets/scss/brands.scss';
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Bebas+Neue&family=Lato:wght@300;400;700&family=Pacifico&family=Raleway:wght@400;600;700&family=Staatliches&display=swap');
@media screen {
  img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  body {
    height: 100vh;
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    a {
      text-decoration: none;
      color: $actionColor;
    }
  }

  main {
    background-position: top;
    background-repeat: repeat;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 270px, #fff 270px), url('../src/assets/img/pattern.png');

    @include respond-to('xsmall') {
      background-size: 85%;
    }
    @include respond-to('small') {
      background-size: 75%;
    }
    @include respond-to('medium') {
      background-size: 65%;
    }
    @include respond-to('large') {
      background-size: 50%;
    }
    @include respond-to('xlarge') {
      background-size: 40%;
    }

    &.page-home {
      @include respond-to('xsmall') {
        background-image: linear-gradient(rgba(0, 0, 0, 0) 500px, #fff 500px), url('../src/assets/img/pattern.png');
      }
      @include respond-to('small') {
        background-image: linear-gradient(rgba(0, 0, 0, 0) 490px, #fff 490px), url('../src/assets/img/pattern.png');
      }
      @include respond-to('xlarge') {
        background-image: linear-gradient(rgba(0, 0, 0, 0) 340px, #fff 340px), url('../src/assets/img/pattern.png');
      }
    }

    &.page-recipe {
      @include respond-to('xsmall') {
        background-image: linear-gradient(rgba(0, 0, 0, 0) 400px, #fff 400px), url('../src/assets/img/pattern.png');
      }
      @include respond-to('small') {
        background-image: linear-gradient(rgba(0, 0, 0, 0) 570px, #fff 570px), url('../src/assets/img/pattern.png');
      }
      @include respond-to('xlarge') {
        background-image: linear-gradient(rgba(0, 0, 0, 0) 380px, #fff 380px), url('../src/assets/img/pattern.png');
      }
    }

    &.page-category {
      background-image: linear-gradient(rgba(0, 0, 0, 0) 180px, #fff 180px), url('../src/assets/img/pattern.png');
    }

    &.page-search {
      background-image: linear-gradient(rgba(0, 0, 0, 0) 280px, #fff 280px), url('../src/assets/img/pattern.png');
    }
  }

  #app {
    font-size: 16px;
    line-height: 1.5;
    @include bodyFont;
    -webkit-font-smoothing: antialiased;
    font-weight: 300;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  .container {
    min-height: calc(100vh - 350px);
    max-width: 1280px;
    width: 90%;
    margin: 0 auto;
  }

  .shopping-list-button {
    display: grid;
    align-content: center;
    justify-content: center;
    border-radius: 50%;
    height: 60px;
    width: 62px;
    border: 2px solid #fff;
    color: #fff;
    background-color: $primaryColorLight;
    font-size: 1.5em;
    position: fixed;
    top: 1em;
    right: 1em;
    cursor: pointer;

    &:hover {
      background-color: $actionColor;
    }

    &:active {
      top: 1.1em;
    }
  }

  #logo {
    text-align: center;
    margin-top: 0;
  }

  .recipe-card {
    grid-auto-flow: columns;
  }

  .loading {
    line-height: 1.5em;
    text-align: center;
    margin: 4em 0;
  }

  .rounded-button {
    display: inline-block;
    background-color: $actionColor;
    color: #fff;
    border-radius: 32px;
    padding: 8px 32px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      background-color: $actionColorDark;
    }
  }

  .recipe-list {
    display: grid;
    gap: 1em;

    @include respond-to('xsmall') {
      grid-template-columns: 1fr;
    }

    @include respond-to('small') {
      grid-template-columns: 1fr 1fr;
    }

    @include respond-to('xlarge') {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@media print {
  .no-print {
    display: none !important;
  }

  body {
    font-size: 16px;
    font-family: 'Josefin Sans', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
</style>
