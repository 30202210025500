<template>
  <footer class="no-print">
    <div>
      <h3>Schrijf je in voor de nieuwsbrief</h3>
      <!-- Begin Mailchimp Signup Form -->
      <div id="mc_embed_signup">
        <form
          action="https://etenbijsindy.us20.list-manage.com/subscribe/post?u=383d9134a076aa9372948d345&amp;id=a74b0f1296"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
          novalidate
        >
          <div id="mc_embed_signup_scroll">
            <div class="mc-field-group">
              <p><label for="mce-EMAIL">Ontvang een mail zodra er een nieuw recept online is!</label></p>
              <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" placeholder="Vul hier je e-mail in..." />
              <input type="submit" value="Inschrijven" name="subscribe" id="mc-embedded-subscribe" class="button formEmailButton" />
            </div>
            <div id="mce-responses" class="clear foot">
              <div class="response" id="mce-error-response" style="display: none"></div>
              <div class="response" id="mce-success-response" style="display: none"></div>
            </div>
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px" aria-hidden="true"><input type="text" name="b_383d9134a076aa9372948d345_a74b0f1296" tabindex="-1" value="" /></div>
          </div>
        </form>
      </div>
      <!--End mc_embed_signup-->
      <div class="policy"><a href="/privacyverklaring">Privacyverklaring</a></div>
    </div>

    <div>
      <h3>Volg mij op social media</h3>
      <ul class="social-media">
        <li>
          <a href="https://www.instagram.com/etenbijsindy" target="_blank" title="Volg Eten bij Sindy op Instagram"><i class="fab fa-instagram"></i></a>
        </li>
        <li>
          <a href="https://nl.pinterest.com/sinnetje/etenbijsindynl-recepten/" target="_blank" title="Volg Eten bij Sindy op Pinterest"><i class="fab fa-pinterest"></i></a>
        </li>
        <li>
          <a href="https://www.facebook.com/etenbijsindy" target="_blank" title="Volg Eten bij Sindy op Facebook"><i class="fab fa-facebook"></i></a>
        </li>
      </ul>
      <h3>Contact</h3>
      <p><i class="far fa-envelope"></i> sindy[at]codulo.nl</p>
    </div>

    <div class="profile">
      <h3>Over mij</h3>
      <img class="profile-picture" src="../assets/img/profielfoto.jpeg" alt="Foto van Sindy" />
      <p>
        Hoi, ik ben Sindy! Ik houd van lekker eten zonder uren in de keuken te hoeven staan. Ik vind het leuk om nieuwe gerechten te ontdekken en ze thuis na te maken. Op
        mijn blog ontdek je wat er regelmatig bij mij op tafel staat en geef ik handige tips voor het koken en bakken. Hopelijk ontdek je wat nieuws om uit te proberen!
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';

footer {
  display: grid;
  background-color: $primaryColorDark;
  color: #fff;
  margin-top: 4em;

  @include respond-to('xsmall') {
    grid-template-columns: 1fr;
  }
  @include respond-to('small') {
    grid-template-columns: 1fr 1fr;
  }
  @include respond-to('xlarge') {
    height: 350px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  h3 {
    @include headerFont;
    font-weight: normal;
  }

  > div {
    padding: 24px 40px 0;

    @include respond-to('xlarge') {
      padding: 24px 40px;
    }
  }
}

.profile {
  padding-top: 0;
  padding-bottom: 24px;

  @include respond-to('small') {
    grid-column: 1 / -1;
  }
  @include respond-to('xlarge') {
    grid-column: auto;
    padding-top: 24px;
  }
}

.social-media {
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin-right: 1em;
  }

  a {
    font-size: 2em;
    color: white;
  }
}

.email {
  height: 44px;
  border: 0;
  padding: 0 8px;
  border-radius: 8px;
  margin-right: 8px;
  margin-top: 0.75em;
  min-width: 160px;

  @include respond-to('medium') {
    width: 200px;
  }
}

.formEmailButton {
  height: 44px;
  padding: 0 16px;
  border: 0;
  background-color: $actionColor;
  border-radius: 8px;
  font-weight: bold;
  color: #fff;
  margin-top: 0.75em;
  cursor: pointer;
}

.policy {
  margin-top: 1.75em;
  a {
    color: #fff;
    font-style: italic;
    border-bottom: 1px solid #fff;
    font-size: 0.85em;
  }
}

.profile-picture {
  height: 100px;
  width: 100px;
  float: left;
  margin-right: 16px;
  border-radius: 8px;
}
</style>
