<template>
  <header>
    <router-link :to="'/recept/' + recipes[0].path" class="recipe-header">
      <img :src="getImg(recipes[0])" :alt="recipes[0].name" />
      <h3>{{ recipes[0].name }}</h3>
    </router-link>
  </header>
  <h1>Nieuwste recepten</h1>
  <div class="recipe-list">
    <template v-for="(recipe, i) in recipes.slice(1, this.loadAmount)" v-bind:key="i">
      <RecipeCard :recipe="recipe" />
    </template>
  </div>
  <div class="loading" v-if="this.loadAmount < recipes.length">
    <div class="rounded-button" @click="loadMore">Bekijk meer recepten <i class="far fa-arrow-right fa-fw"></i></div>
  </div>

  <!-- <template v-if="showTips">
    <h1>Kook- en baktips</h1>
    <div class="recipe-list">
      <template v-for="(tip, i) in tips.slice(0, 3)" v-bind:key="i">
        <TipCard :tip="tip" />
      </template>
    </div>
    <div class="loading">
      <a class="rounded-button" href="">Bekijk meer tips <i class="far fa-arrow-right fa-fw"></i></a>
    </div>
  </template> -->
</template>

<script>
// @ is an alias to /src
import RecipeCard from '@/components/RecipeCard.vue'
// import TipCard from '@/components/TipCard.vue'
import titleMixin from '@/assets/mixins/titleMixin'

export default {
  name: 'Home',
  components: {
    RecipeCard
    // TipCard
  },
  data () {
    return {
      loadAmount: 10
    }
  },
  props: {
    recipes: Array,
    tips: Array
  },
  methods: {
    getImg (recipe) {
      const images = require.context('@/recipes/', true, /\.jpg|\.jpeg|\.png$/)
      try {
        return images('./' + recipe.path + '/' + recipe.image)
      } catch {
        return require('@/assets/img/404.jpg')
      }
    },
    loadMore () {
      this.loadAmount += 6
    }
  },
  mixins: [titleMixin],
  title () {
    return 'Eten bij Sindy – Alle favoriete recepten van Sindy'
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';

header {
  padding: 0;
  height: 448px;

  img {
    @include roundedCornersLarge;
    width: 100%;
    height: 448px;
    object-fit: cover;

    @include respond-to('xlarge') {
      @include roundedCornersBottomLarge;
    }
  }
}

.menu {
  border-radius: 0;
  background-color: red;
}

.recipe-header {
  @include roundedCornersBottomLarge;
  position: relative;
  z-index: 1;
  display: block;

  h3 {
    @include bannerFont;
    background-color: $primaryColor;
    color: #fff;
    height: 64px;
    line-height: 64px;
    border-radius: 0 8px 8px 0;
    text-align: left;
    margin: 0;
    padding: 0 1.5em;
    bottom: 2.5em;
    position: absolute;
    font-weight: normal;

    @include respond-to('xsmall') {
      font-size: 24px;
      height: 56px;
      line-height: 56px;
      bottom: 3em;
      height: auto;
      text-align: center;
    }

    @include respond-to('small') {
      font-size: 36px;
    }
  }
}

h1 {
  @include subheaderFont;
  color: $primaryColor;
  border-top: 1px solid $primaryColor;
  border-bottom: 1px solid $primaryColor;
  padding: 0.25em;
  text-align: center;
  font-size: 2em;
  font-weight: normal;
}
</style>
