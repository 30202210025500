<template>
  <nav class="main-menu no-print noselect">
    <ul class="menu-bar">
      <li class="home"><router-link to="/">Home</router-link></li>
      <li class="recepten"><router-link to="/recepten">Recepten</router-link></li>
      <li class="logo">
        <router-link to="/"><img src="@/assets/img/eten-bij-sindy-logo.svg" height="160" id="logo" /></router-link>
      </li>
      <li class="tips-en-trucs"><router-link to="/tips-en-trucs">Kooktips</router-link></li>
      <li class="omrekentool"><router-link to="/omrekentool">Omrekenen</router-link></li>
      <li class="search">
        <router-link to="/zoeken"><i class="fa fa-search"></i></router-link>
      </li>
    </ul>
  </nav>
  <div class="mobile-navigation no-print noselect">
    <div style="text-align: center">
      <router-link to="/"><img src="@/assets/img/eten-bij-sindy-logo.svg" height="160" id="logo" /></router-link>
    </div>
    <div class="menu-bar no-print" @click="toggleMenu">
      <div href="" class="menu-button"><i class="fas fa-bars"></i> Menu</div>
    </div>
    <nav v-if="showMenu" class="mobile-menu-drawer no-print" @click="toggleMenu">
      <ul>
        <li class="home">
          <router-link to="/"><i class="far fa-fw fa-home"></i> Home</router-link>
        </li>
        <li class="recepten">
          <router-link to="/recepten"><i class="far fa-fw fa-book"></i> Recepten</router-link>
        </li>
        <li class="tips-en-trucs">
          <router-link to="/tips"><i class="far fa-fw fa-hat-chef"></i> Kooktips</router-link>
        </li>
        <li class="omrekentool">
          <router-link to="/omrekentool"><i class="far fa-fw fa-calculator"></i> Omrekenen</router-link>
        </li>
        <li class="search">
          <router-link to="/zoeken"><i class="fa fa-fw fa-search"></i> Zoeken</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  data () {
    return {
      showMenu: false
    }
  },
  props: {},
  methods: {
    toggleMenu () {
      this.showMenu = !this.showMenu
    },
    scrollFunction () {
      if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        document.getElementById('navbar').style.padding = '30px 10px'
        document.getElementById('logo').style.fontSize = '25px'
      } else {
        document.getElementById('navbar').style.padding = '80px 10px'
        document.getElementById('logo').style.fontSize = '35px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';

.menu-bar {
  background-color: $primaryColorDark;
  height: 64px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.page-home .menu-bar {
  @include roundedCornersLarge;

  @include respond-to('xlarge') {
    @include roundedCornersTopLarge;
  }
}

.main-menu {
  padding-top: 64px;
  position: relative;
  z-index: 10;
  display: none;

  ul {
    margin: 0;
    text-align: center;

    li {
      @include headerFont;
      flex: 1;
      display: inline;
    }

    .home {
      flex: 1.1;
    }

    .recepten {
      flex: 1.2;
    }

    .logo {
      flex: 1;
    }

    .search {
      flex: 0.5;
    }

    a {
      width: 60%;
      height: 62px;
      line-height: 64px;
      font-weight: normal;
      color: #fff;
      font-size: 20px;
      display: inline-block;

      &:hover {
        color: rgba(#fff, 0.8);
      }
    }
  }

  @include respond-to('xlarge') {
    display: block;
  }
}

.mobile-navigation {
  display: block;
  padding-top: 16px;
  margin-bottom: 1em;
  cursor: pointer;

  @include respond-to('xlarge') {
    display: none;
    margin-bottom: 0;
  }
}

.menu-button {
  color: #fff;
  margin-left: 1em;
  font-size: 1.5em;
  font-weight: bold;

  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  i {
    padding-right: 0.5em;
  }
}

.mobile-menu-drawer {
  @include roundedCornersBottomLarge;
  margin: 0 16px 0 16px;
  padding: 8px 6px 16px;
  background-color: $primaryColorLight;
  z-index: 100;

  ul {
    display: block;
    font-size: 1em;
    list-style: none;
    margin: 0;

    li {
      line-height: 2;
    }
  }

  a {
    color: #fff;

    &:hover {
      color: rgba($secondaryColor, 0.9);
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
